import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player';

import styled from 'styled-components';
import { Header, Category } from '../components/layout/index.js';
import { Section, Container, Button } from '../components/common/index.js';

const Work = ({ data }) => (
  <>
    <Helmet>
      <title>
        Work and travel packages in Australia and New Zealand with Base
        Backpackers
      </title>
      <meta
        name="description"
        content="Tailor made packages for people traveling to Australia and New Zealand with working holiday visas. Arrive in style and secure employment fast with Base Backpackers"
      />
    </Helmet>
    <Header
      backgroundImage={data.file.childImageSharp.fluid}
      pageTitle="Work and travel packages"
      tagline="Fund your adventures"
      // propertyName="Base Magnetic Island"
      // caption="Sunset Walking Tour"
    />

    <Section>
      <Container>
        <h2>Working in Australia</h2>
        <p>
          Chris Hemsworth says: “ It’s different down here, the air has more
          life in it. Because Australia isn’t just a place you see it’s a place
          you feel. That’s why there’s nothing like it!”
        </p>
        <p>
          Come and explore this great land, learn to surf just like Chris and
          his mates, you might even catch a glimpse of Thor as you’re learning
          to surf in Byron Bay!
        </p>
        <div
          style={{
            position: 'relative',
            paddingTop: '56.25%'
          }}
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=jHFz11Wasvg"
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              top: '0',
              left: '0'
            }}
          />
        </div>
        <p>
          Explore the golden outback, enjoy the Aussie nightlife, parties,
          festivals and events. But, most of all define your own adventure
          whether it’s adrenaline filled, seeking out our weird and wonderful
          wildlife, getting to know the friendly locals or enjoying our endless
          coastlines.
        </p>
        <p>
          We welcome thousands of mates down under every year, and we want to
          help you have the adventure of a life time, with rock solid support
          when you need it the whole time you’re here!
        </p>

        <h2>Great value packages to help get you started</h2>
        <p>
          With our flexible Work &amp; Travel packages, not only will we show
          you a great time and introduce you to like-minded working holiday
          makers, but we also have the inside knowledge to provide you with
          everything you need to get started and get a job in Australia and New
          Zealand. So get involved and spend your time away sleeping with us…
          you will certainly learn a thing or two in the process!
        </p>
      </Container>
      <hr />
      <Container col="2">
        <div>
          <h2>Choose Your Package</h2>
          <h3>Australian Arrivals Packages:</h3>
          <p>
            Choose your arrival location on the left (Brisbane, Melbourne,
            Sydney , the number of nights you want to stay (3, 5 or 7), and the
            number of people purchasing the package.
          </p>
          <h3>Work &amp; Travel Packages include:</h3>
          <ul>
            <li>3, 4, 5 or 7 Nights Accommodation</li>
            <li>Daily Continental Breakfast</li>
            <li>Airport Transfer to the hostel</li>
            <li>12 Months Membership</li>
            <li>*In depth local orientation</li>
            <li>Free WIFI for length of stay</li>
            <li>One on one CV building and jobs consultation</li>
            <li>Australian / NZ SIM Card</li>
            <li>12 months access to the Work Australia online jobs board</li>
            <li>
              12 months access to Work And New Zealand offices AU &amp; NZ
            </li>
            <li>Free activities in each location</li>
          </ul>
          <h4>Australia Arrivals Packages:</h4>
          <ul>
            <li>Opening a Bank Account</li>
            <li>Tax File Number set up</li>
            <li>Superannuation application</li>
            <li>Assistance with tax and superannuation refund</li>
            <li>2nd Year Visa and migration help</li>
            <li>Discounted training courses (RSA/White card etc)</li>
            <li>140-page Australian Harvest Guidebook</li>
            <li>Discounted WWOOF membership</li>
          </ul>
        </div>
        <div>
          {data.au.edges.map(({ node: workau }) => (
            <div style={{ margin: '1rem' }}>
              <Category
                title={workau.title}
                fluid={workau.featuredImage.fluid}
                key={workau.id}
                alt={workau.alt}
                url={`/deals/${workau.slug}`}
              />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  </>
);

export default Work;

export const query = graphql`
  query {
    file(relativePath: { eq: "1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    au: allDatoCmsProduct(
      filter: {
        locale: { eq: "en" }
        productCategorie: { elemMatch: { title: { eq: "Work and travel AU" } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          productCategorie {
            title
          }
        }
      }
    }
    nz: allDatoCmsProduct(
      filter: {
        locale: { eq: "en" }
        productCategorie: { elemMatch: { title: { eq: "Work and travel NZ" } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          productCategorie {
            title
          }
        }
      }
    }
  }
`;
